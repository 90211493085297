import { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import './map_with_branches.style.scss';
import Geocode from 'react-geocode';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import AutoComplete from 'react-google-autocomplete';
import { useDispatch } from 'react-redux';
import { fetchBranches } from 'redux/actions/branches/fetch_branches.action';
import { deleteAllFromCart } from 'redux/actions/cart.action';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
let count = 1;

export const MapWithBranches = ({showModal, setShowModal, branches}) => {
    const dispatch = useDispatch();
    const [canCloseModal, setCanCloseModal] = useState(false);
    const [branchId, setBranchId] = useState("");
    const [address, setAddress] = useState("");
    const [markertPosition, setMarkerPosition] = useState({lat:31.4697, lng:74.2728});

    const placeSelectedHandler = (place) => {
        setAddress(place.formatted_address);
        setMarkerPosition({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
    }

    const { setting } = useSelector((state) => state.layoutSetting);
    const dynamicStyle = {
        color: setting.web_font_color,
        fontStyle: setting.web_font_style,
        fontVariant: setting.web_font_variant,
        fontWeight: setting.web_font_weight,
    };
    useEffect(()=>{
       
        setCanCloseModal(localStorage.getItem("branch_id") && localStorage.getItem("branch_id") !== "");
        dispatch(fetchBranches(markertPosition));
        if(count === 1){
            Geocode.fromLatLng(markertPosition.lat, markertPosition.lng).then(result => {
                setAddress(result.results[0]?.formatted_address);
            });
            count++;
        } 
    }, [dispatch, markertPosition]);

    const options = {
        types: ["geocode", "establishment"]
    }

    const MapWithSearchbarAndMore =  withScriptjs(
        withGoogleMap(props => (
            <GoogleMap
                defaultCenter={markertPosition}
                defaultZoom={props.zoom}
            >
                <AutoComplete 
                    onPlaceSelected = {placeSelectedHandler}
                    options = {options}
                    className='form-control address-selector'
                    style={dynamicStyle}
                />
                <Marker position={markertPosition} />
            </GoogleMap>
        ))
    );
    
    const changeBranchHandler = (e) => {
        setBranchId(e.target.value);
    }
    const nextHandler = () => {
        if(branchId !== ""){
            const localBranchId = localStorage.getItem("branch_id") ? JSON.parse(localStorage.getItem("branch_id")) : null;
            if(localBranchId !== branchId){
                confirmAlert({
                    customUI: ({ onClose }) => {
                        const confirmHandler = () => {
                            localStorage.removeItem('cartItems');
                            dispatch(deleteAllFromCart());
                            localStorage.setItem("branch_id", JSON.stringify(branchId));
                            localStorage.setItem("lat_lng", JSON.stringify(markertPosition));
                            onClose();
                            setBranchId("");
                            setShowModal(false);
                        }
                        const closeHandler = () => {
                            onClose();
                            setBranchId("");
                            setShowModal(false);
                        }

                        return<>
                            <div className="react-confirm-alert-body">
                                <h1 style={dynamicStyle}>Branch Switching</h1>
                                <span style={dynamicStyle}>Are you sure want to switch branch? If you switch branch your cart items will be deleted</span>
                                <div className="react-confirm-alert-button-group">
                                    <button label="Yes, Switch" onClick={ confirmHandler } style={{...dynamicStyle, background: setting.web_background_color}}>Yes, Switch</button>
                                    <button label="No" onClick={ closeHandler } style={{...dynamicStyle, background: setting.web_background_color}}>No</button>
                                </div>
                            </div>
                        </>
                    }
                });
            }else{
                localStorage.setItem("branch_id", JSON.stringify(branchId));
                localStorage.setItem("lat_lng", JSON.stringify(markertPosition));
                setBranchId("");
                setShowModal(false);
            }
        }
    }
    
    return <>
            <Modal show={showModal} onHide={() => setShowModal(!canCloseModal)} className='map-modal-container'>
                <Modal.Body style={{paddingBottom: "1rem"}}>
                    <Form.Control 
                        type="input"
                        value={address}
                        readOnly
                        className='address-readonly'
                        style={dynamicStyle}
                    />
                    <MapWithSearchbarAndMore
                        googleMapURL = {`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                        loadingElement = {<div style={{ height: `100%` }} />}
                        containerElement = {<div style={{ height: `400px` }} />}
                        mapElement = {<div style={{ height: `100%` }} />}
                        zoom = {15}
                        showMarker = {true}
                    />
                    {
                        branches.length ? 
                            (<Row className='branches-list'>
                                <Col sm={12} md={12} lg={12}> 
                                    <InputGroup>
                                        <InputGroup.Text style={{ ...dynamicStyle, background: setting.web_background_color }}>Branches</InputGroup.Text>
                                        <Form.Select defaultValue="" onChange={ changeBranchHandler } style={dynamicStyle}>
                                            <option value="" disabled style={dynamicStyle}> Select Branch...</option>
                                            { branches.map( branch => <option value={branch.id} key={branch.id} style={dynamicStyle}>{branch.en_title}</option>) }
                                        </Form.Select>
                                        <Button onClick={ nextHandler } style={{ ...dynamicStyle, background: setting.web_background_color, borderColor: setting.web_background_color }}> Next </Button>
                                    </InputGroup>         
                                </Col>
                            </Row>)
                            : (
                                <Row className='branches-list'>
                                    <Col sm={12} md={12} lg={12}> 
                                        <InputGroup>
                                            <InputGroup.Text style={{ ...dynamicStyle, background: setting.web_background_color, width: '100%', display: 'block' }}>No branch found please change location</InputGroup.Text>
                                        </InputGroup>         
                                    </Col>
                                </Row>
                            )
                    }
                    
                </Modal.Body>
            </Modal>
    </>
};