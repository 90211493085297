import { combineReducers } from 'redux';
import { mealsReducer } from './meals/meals.reducer';
import { compareReducer } from './compare/compare.reducer';
import { wishlistReducer } from "./wishlist/wishlist.reducer";
import { signupReducer } from "./signup/signup.reducer";
import { loginReducer } from "./login/login.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { ordersListReducer } from "./order/orders.reducer";
import { branchesReducer } from './branches/branches.reducer';
import { layoutSettingReducer } from './layout_setting/layout_setting.reducer';
import { promotionalOffersList } from './promotional_offers/promotional_offers.reducer';
import { generalSettingReducer } from "./setting/generalSetting.reducer";
import currencyReducer from "./currency/currencyReducer";
import { ordersDetailReducer } from "./order/orders.reducer";
import {addressReducer} from "./address/address.reducer";
import { profileReducer } from "./profile/profile.reducer";
import { reviewsListReducer } from './reviews/reviews_list.reducer';
import {paymentMethodReducer} from './paymentMethod/paymentMethod.reducer'
import { branchDetailReducer } from './branches/branch_detail.reducer';
import { DealOfDayReducer } from "./deals/deal.reducer";
import { offersReducer } from "./offers/offers.reducer";
import {searchText}  from "./search/search.reducer";




export const rootReducer = combineReducers({
    currencyData: currencyReducer,
    meals: mealsReducer,
    address:addressReducer,
    branches: branchesReducer,
    compareData: compareReducer,
    wishlistData: wishlistReducer,
    profile: profileReducer,
    signup: signupReducer,
    paymentMethod:paymentMethodReducer,
    login: loginReducer,
    cartData: cartReducer,
    generalSetting: generalSettingReducer,
    ordersList: ordersListReducer,
    orderDetail:ordersDetailReducer,
    layoutSetting: layoutSettingReducer,
    promotionalOffers: promotionalOffersList,
    reviewsList: reviewsListReducer,
    branchDetail: branchDetailReducer,
    dealOfDay: DealOfDayReducer,
    offers: offersReducer,
    search:searchText
});