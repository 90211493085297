import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './reducers/root.reducer';



const localStorageUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
const localStorageCompareItems = localStorage.getItem('compareItems') ? JSON.parse(localStorage.getItem('compareItems')) : [];
const localStorageCartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
const localStorageWishListItems = localStorage.getItem('wishList') ? JSON.parse(localStorage.getItem('wishList')) : [];

const initState = {
    login: {user: localStorageUser},
    compareData: localStorageCompareItems,
    cartData: localStorageCartItems,
    wishlistData: localStorageWishListItems 
};
const middleware = [thunk];

export const store = createStore(
    rootReducer,
    initState,

    composeWithDevTools( applyMiddleware( ...middleware ) )
);
