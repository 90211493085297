const Constant = {
  ADDRESS_FETCHING: "Address fetching",
  ADDRESS_FETCHED: "Address fetched",
  ADDRESS_FETCHING_FAILED: "Address fetching failed",
  ADDRESS_ADDING_IN_PROCESS: "Address adding in process",
  ADDRESS_ADDED: "Address added",
  ADDRESS_ADDED_FAILED: "Address added failed",
};

export default Constant;
