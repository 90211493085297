import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "common/assets/scss/global.scss";

let container = null;
let root = null;
container = document.getElementById("root");
root      = createRoot(container);
root.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
);
reportWebVitals();
