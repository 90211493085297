import { Spinner } from "react-bootstrap";
import style from "common/assets/scss/components/loader.module.scss";

const Loader = () => {
  return (
    <div className={style.container}>
      <Spinner animation="grow" className="text-primary" />
    </div>
  );
};

export default Loader;
