import { Constant } from "redux/constants/generalSetting.constant";

const initState = {
    setting: {}
};

export const generalSettingReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.GENERAL_SETTING_FETCHING:
            return { ...state, is_loading: true, error: null, setting: {} };
        case Constant.GENERAL_SETTING_FETCHED:
            return { ...state, is_loading: false, error: null, setting: action.payload };
        case Constant.GENERAL_SETTING_FETCHING_FAILED:
            return { ...state, is_loading: false, error: action.payload, setting: {} };
        case Constant.GENERAL_SETTING_CLEAR_ERRORS:
            return { is_loading: false, error: null, setting: {} };
        default:
            return state;
    }
}