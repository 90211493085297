import { Fragment, lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastProvider } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { fetchLayoutSetting } from "redux/actions/layout_setting/fetch_layout_setting.action";
import { fetchGeneralSetting } from "redux/actions/generalSetting.action";
import Loader from 'common/components/loader/loader.component';
import { MapWithBranches as MapWithBranchesThemeOne } from 'theme1/components/modals/map_with_branches.component/map_with_branches.component';
import { MapWithBranches as MapWithBranchesThemeTwo} from 'theme2/components/modals/map_with_branches.component/map_with_branches.component';
import { MapWithBranches as MapWithBranchesThemeThree } from 'theme3/components/modals/map_with_branches.component/map_with_branches.component';

let theme1 = "theme1";
const HomePageThemeOneTest              = lazy(() => import(`${theme1}/pages/Home`));
const LoginPageThemeOne                 = lazy(() => import(`${theme1}/pages/authentication/Login`));
const LogoutPageThemeOne                = lazy(() => import(`${theme1}/pages/authentication/Logout`));
const SignUpPageThemeOne                = lazy(() => import(`${theme1}/pages/authentication/Signup`));
const ResetPasswordThemeOne             = lazy(() => import(`${theme1}/pages/authentication/ResetPassword`));
const ShoppingCartThemeOne              = lazy(() => import(`${theme1}/pages/ShoppingCart`));
const OrderPlacedThemeOne               = lazy(() => import(`${theme1}/pages/OrderPlaced`));
const OrderProcessingThemeOne           = lazy(() => import(`${theme1}/pages/OrderProcessing`));
const AddPaymentCardThemeOne            = lazy(() => import(`${theme1}/pages/AddPaymentCard`));
const ProductsCompareThemeOne           = lazy(() => import(`${theme1}/pages/products/ProductsCompare`));
const UserProfileThemeOne               = lazy(() => import(`${theme1}/pages/profile/UserProfile`));
const SelectPaymentMethodThemeOne       = lazy(() => import(`${theme1}/pages/SelectPaymentMethod`));
const AddOrderReviewThemeOne            = lazy(() => import(`${theme1}/pages/AddOrderReview`));
const OrderTrackingThemeOne             = lazy(() => import(`${theme1}/pages/OrderTracking`));
const TermsConditionThemeOne            = lazy(() => import(`${theme1}/pages/TermsCondition`));
const PrivacyPolicyThemeOne             = lazy(() => import(`${theme1}/pages/PrivacyPolicy`));
const NavbarHeaderFooterLayoutThemeOne  = lazy(() => import(`${theme1}/layouts/navbar_header_footer.layout`));
const SimpleHeaderNoFooterThemeOne      = lazy(() => import(`${theme1}/layouts/simple_header_no_footer.layout`));
const StorenotreadyThemeOne             = lazy(() => import(`${theme1}/components/general/storeNotReady`));
const Error404ThemeOne                  = lazy(() => import(`${theme1}/components/general/error404`));
const PaymentThemeOne                   = lazy(() => import(`${theme1}/pages/payment.page`));

let theme2 = "theme2";
const HomePageThemeTwo                  = lazy(() => import(`${theme2}/pages/Home`));
const LoginPageThemeTwo                 = lazy(() => import(`${theme2}/pages/authentication/Login`));
const LogoutPageThemeTwo                = lazy(() => import(`${theme2}/pages/authentication/Logout`));
const SignUpPageThemeTwo                = lazy(() => import(`${theme2}/pages/authentication/Signup`));
const ResetPasswordThemeTwo             = lazy(() => import(`${theme2}/pages/authentication/ResetPassword`));
const ShoppingCartThemeTwo              = lazy(() => import(`${theme2}/pages/ShoppingCart`));
const OrderPlacedThemeTwo               = lazy(() => import(`${theme2}/pages/OrderPlaced`));
const OrderProcessingThemeTwo           = lazy(() => import(`${theme2}/pages/OrderProcessing`));
const AddPaymentCardThemeTwo            = lazy(() => import(`${theme2}/pages/AddPaymentCard`));
const ProductsCompareThemeTwo           = lazy(() => import(`${theme2}/pages/products/ProductsCompare`));
const UserProfileThemeTwo               = lazy(() => import(`${theme2}/pages/profile/UserProfile`));
const SelectPaymentMethodThemeTwo       = lazy(() => import(`${theme2}/pages/SelectPaymentMethod`));
const AddOrderReviewThemeTwo            = lazy(() => import(`${theme2}/pages/AddOrderReview`));
const OrderTrackingThemeTwo             = lazy(() => import(`${theme2}/pages/OrderTracking`));
const TermsConditionThemeTwo            = lazy(() => import(`${theme2}/pages/TermsCondition`));
const PrivacyPolicyThemeTwo             = lazy(() => import(`${theme2}/pages/PrivacyPolicy`));
const NavbarHeaderFooterLayoutThemeTwo  = lazy(() => import(`${theme2}/layouts/navbar_header_footer.layout`));
const SimpleHeaderNoFooterThemeTwo      = lazy(() => import(`${theme2}/layouts/simple_header_no_footer.layout`));
const StorenotreadyThemeTwo             = lazy(() => import(`${theme2}/components/general/storeNotReady`));
const Error404ThemeTwo                  = lazy(() => import(`${theme2}/components/general/error404`));
const PaymentThemeTwo                   = lazy(() => import(`${theme2}/pages/payment.page`));

let theme3 = "theme3";
const HomePageThemeThree                  = lazy(() => import(`${theme3}/pages/Home`));
const LoginPageThemeThree                 = lazy(() => import(`${theme3}/pages/authentication/Login`));
const LogoutPageThemeThree                = lazy(() => import(`${theme3}/pages/authentication/Logout`));
const SignUpPageThemeThree                = lazy(() => import(`${theme3}/pages/authentication/Signup`));
const ResetPasswordThemeThree             = lazy(() => import(`${theme3}/pages/authentication/ResetPassword`));
const ShoppingCartThemeThree              = lazy(() => import(`${theme3}/pages/ShoppingCart`));
const OrderPlacedThemeThree               = lazy(() => import(`${theme3}/pages/OrderPlaced`));
const OrderProcessingThemeThree           = lazy(() => import(`${theme3}/pages/OrderProcessing`));
const AddPaymentCardThemeThree            = lazy(() => import(`${theme3}/pages/AddPaymentCard`));
const ProductsCompareThemeThree           = lazy(() => import(`${theme3}/pages/products/ProductsCompare`));
const UserProfileThemeThree               = lazy(() => import(`${theme3}/pages/profile/UserProfile`));
const SelectPaymentMethodThemeThree       = lazy(() => import(`${theme3}/pages/SelectPaymentMethod`));
const AddOrderReviewThemeThree            = lazy(() => import(`${theme3}/pages/AddOrderReview`));
const OrderTrackingThemeThree             = lazy(() => import(`${theme3}/pages/OrderTracking`));
const TermsConditionThemeThree            = lazy(() => import(`${theme3}/pages/TermsCondition`));
const PrivacyPolicyThemeThree             = lazy(() => import(`${theme3}/pages/PrivacyPolicy`));
const NavbarHeaderFooterLayoutThemeThree  = lazy(() => import(`${theme3}/layouts/navbar_header_footer.layout`));
const SimpleHeaderNoFooterThemeThree      = lazy(() => import(`${theme3}/layouts/simple_header_no_footer.layout`));
const StorenotreadyThemeThree             = lazy(() => import(`${theme3}/components/general/storeNotReady`));
const Error404ThemeThree                  = lazy(() => import(`${theme3}/components/general/error404`));
const PaymentThemeThree                   = lazy(() => import(`${theme3}/pages/payment.page`));

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const { list } = useSelector((state) => state.branches);
  const { is_loading, error, setting } = useSelector( (state) => state.generalSetting );
  const { is_loading: layoutSettingLoading, error: layoutSettingError, setting: layoutSetting } = useSelector( (state) => state.layoutSetting );

  useEffect(() => {
    if (error === "Not found" || (setting.id && !setting.title) || (layoutSettingLoading === false && !layoutSetting.theme)) {
      setShowModal(false);
      navigate("/503");
    }
  }, [is_loading, location.pathname]);

  useEffect(() => {
    const showBranchModal =
    localStorage.getItem("branch_id") &&
    localStorage.getItem("branch_id") !== ""
      ? false
      : true;
    setShowModal(showBranchModal);
    dispatch(fetchGeneralSetting());
    dispatch(fetchLayoutSetting());
  }, [dispatch]);

  if(!layoutSetting.theme){
    return <Loader />;
  }

  if(layoutSetting.web_favicon){
    const favicon = document.querySelector("link[rel='icon']");
    favicon.href = layoutSetting.web_favicon;
  }

  const selectedTheme = layoutSetting.theme;
  if(selectedTheme === "theme1"){

    return <>
      <Fragment>
        <ToastProvider placement="bottom-left">
          <ToastContainer />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                path="/"
                element={
                  <NavbarHeaderFooterLayoutThemeOne
                    showBranchesModal={showModal}
                    setShowBranchesModal={setShowModal}
                    setting={setting}
                  />
                }
              >
                <Route path="/" element={<HomePageThemeOneTest showModal={showModal} />} />
                <Route path="/home" element={<HomePageThemeOneTest showModal={showModal} />} />
                <Route path="/logout" element={<LogoutPageThemeOne />} />
                <Route path="/cart" element={<ShoppingCartThemeOne />} />
                <Route path="/order-placed/:id" element={<OrderPlacedThemeOne />} />
                <Route
                  path="/processing/order/:id"
                  element={<OrderProcessingThemeOne />}
                />
                <Route path="/add-card" element={<AddPaymentCardThemeOne />} />
                <Route path="/compare" element={<ProductsCompareThemeOne />} />
                <Route path="/profile" element={<UserProfileThemeOne />} />
                <Route path="/review" element={<AddOrderReviewThemeOne />} />
                <Route path="/order-tracking" element={<OrderTrackingThemeOne />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditionThemeOne />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicyThemeOne />} />
                <Route
                  path="/select-payment-method"
                  element={<SelectPaymentMethodThemeOne />}
                />
                <Route path="/pay" element={ <PaymentThemeOne /> } />
              </Route>
              <Route path="/" element={<SimpleHeaderNoFooterThemeOne setting={setting} />}>
                <Route path="/login" element={<LoginPageThemeOne />} />
                <Route path="/register" element={<SignUpPageThemeOne />} />
                <Route path="/reset-password" element={<ResetPasswordThemeOne />} />
                <Route path="/503" element={<StorenotreadyThemeOne />} />
                <Route path="*" element={<Error404ThemeOne />} />
              </Route>
            </Routes>
          </Suspense>
        </ToastProvider>
        <MapWithBranchesThemeOne
          showModal={showModal}
          setShowModal={setShowModal}
          branches={list}
        />
      </Fragment>
    </>
  }
  else if(selectedTheme === "theme2"){
    return <>
      <Fragment>
        <ToastProvider placement="bottom-left">
          <ToastContainer />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                path="/"
                element={
                  <NavbarHeaderFooterLayoutThemeTwo
                    showBranchesModal={showModal}
                    setShowBranchesModal={setShowModal}
                    setting={setting}
                  />
                }
              >
                <Route path="/" element={<HomePageThemeTwo showModal={showModal} />} />
                <Route path="/home" element={<HomePageThemeTwo showModal={showModal} />} />
                <Route path="/logout" element={<LogoutPageThemeTwo />} />
                <Route path="/cart" element={<ShoppingCartThemeTwo />} />
                <Route path="/order-placed/:id" element={<OrderPlacedThemeTwo />} />
                <Route
                  path="/processing/order/:id"
                  element={<OrderProcessingThemeTwo />}
                />
                <Route path="/add-card" element={<AddPaymentCardThemeTwo />} />
                <Route path="/compare" element={<ProductsCompareThemeTwo />} />
                <Route path="/profile" element={<UserProfileThemeTwo />} />
                <Route path="/review" element={<AddOrderReviewThemeTwo />} />
                <Route path="/order-tracking" element={<OrderTrackingThemeTwo />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditionThemeTwo />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicyThemeTwo />} />
                <Route
                  path="/select-payment-method"
                  element={<SelectPaymentMethodThemeTwo />}
                />
                <Route path="/pay" element={ <PaymentThemeTwo /> } />
              </Route>
              <Route path="/" element={<SimpleHeaderNoFooterThemeTwo setting={setting} />}>
                <Route path="/login" element={<LoginPageThemeTwo />} />
                <Route path="/register" element={<SignUpPageThemeTwo />} />
                <Route path="/reset-password" element={<ResetPasswordThemeTwo />} />
                <Route path="/503" element={<StorenotreadyThemeTwo />} />
                <Route path="*" element={<Error404ThemeTwo />} />
              </Route>
            </Routes>
          </Suspense>
        </ToastProvider>
        <MapWithBranchesThemeTwo
          showModal={showModal}
          setShowModal={setShowModal}
          branches={list}
        />
      </Fragment>
    </>
  }else if(selectedTheme === "theme3"){
   
    return <>
      <Fragment>
        <ToastProvider placement="bottom-left">
          <ToastContainer />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                path="/"
                element={
                  <NavbarHeaderFooterLayoutThemeThree
                    showBranchesModal={showModal}
                    setShowBranchesModal={setShowModal}
                    setting={setting}
                  />
                }
              >
                <Route path="/" element={<HomePageThemeThree showModal={showModal} />} />
                <Route path="/home" element={<HomePageThemeThree showModal={showModal} />} />
                <Route path="/logout" element={<LogoutPageThemeThree />} />
                <Route path="/cart" element={<ShoppingCartThemeThree />} />
                <Route path="/order-placed/:id" element={<OrderPlacedThemeThree />} />
                <Route
                  path="/processing/order/:id"
                  element={<OrderProcessingThemeThree />}
                />
                <Route path="/add-card" element={<AddPaymentCardThemeThree />} />
                <Route path="/compare" element={<ProductsCompareThemeThree />} />
                <Route path="/profile" element={<UserProfileThemeThree />} />
                <Route path="/review" element={<AddOrderReviewThemeThree />} />
                <Route path="/order-tracking" element={<OrderTrackingThemeThree />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditionThemeThree />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicyThemeThree />} />
                <Route
                  path="/select-payment-method"
                  element={<SelectPaymentMethodThemeThree />}
                />
                <Route path="/pay" element={ <PaymentThemeThree /> } />
              </Route>
              <Route path="/" element={<SimpleHeaderNoFooterThemeThree setting={setting} />}>
                <Route path="/login" element={<LoginPageThemeThree />} />
                <Route path="/register" element={<SignUpPageThemeThree />} />
                <Route path="/reset-password" element={<ResetPasswordThemeThree />} />
                <Route path="/503" element={<StorenotreadyThemeThree />} />
                <Route path="*" element={<Error404ThemeThree />} />
              </Route>
            </Routes>
          </Suspense>
        </ToastProvider>
        <MapWithBranchesThemeThree
          showModal={showModal}
          setShowModal={setShowModal}
          branches={list}
        />
      </Fragment>
    </>
  }
  
};

export default App;
