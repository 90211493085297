import Constant from "redux/constants/cart.constant";
import { store } from "redux/store";
import constant from "common/utils/constant";
//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  increaseQty = false
) => async(dispatch) => {

    // const cartItemsList = store.getState().cartData;
    // const itemExists = cartItemsList.find( listItem => listItem.id === item.id);
    // if(itemExists){
    //   return addToast("Item already exists in cart", {
    //     appearance: "info",
    //     autoDismiss: true
    //   });
    // }

    if (addToast) {
      addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    }

    if(!increaseQty){
      
      const Additions = [];
      let additionsPrice = 0;
      let size = item?.Additions?.length;
      for(let i = 0; i < size; i ++){
        Additions.push(item.Additions[i]?.id);
        if(item?.Additions[i]?.price){
          additionsPrice += parseFloat(item?.Additions[i]?.price);
        }
      }

      const specialRequests = [];
      let specialRequestsPrice = 0;
      size = item?.specialRequests?.length;

      for(let i = 0; i < size; i ++){
        specialRequests.push(item?.specialRequests[i]?.id);
        if(item?.specialRequests[i]?.price){
          specialRequestsPrice += parseFloat(item?.specialRequests[i]?.price);
        }
      }
      dispatch({
        type: Constant.ADD_TO_CART,
        payload: {
          id: item.id,
          image: item.offer_image ? constant?.OFFERS_IMAGE_URL+item.offer_image : constant?.MEALS_IMAGE_URL+item.image,
          meal_id: item.id,
          title: item.en_offer_name ? item.en_offer_name : item.en_title,
          price: parseFloat(item.price),
          amount: quantityCount ? quantityCount : 1,
          additions: Additions.length ? Additions : null,
          special_requests: specialRequests.length ? specialRequests : null,
          is_offer: item.offer_image ? true: false,
          note: item.instruction ? item.instruction : "",
          additionsPrice: parseFloat(additionsPrice),
          specialRequestsPrice: parseFloat(specialRequestsPrice),
          en_description: item?.en_description
          
        }
      });
    }else{
      dispatch({
        type: Constant.ADD_TO_CART,
        payload: {
         ...item,
         amount: quantityCount
        }
      });
    }
    localStorage.setItem("cartItems", JSON.stringify(store.getState().cartData));
    return;
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) { 
      addToast("Item Removed From Cart", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({ type: Constant.DECREASE_QUANTITY, payload: item });
    localStorage.setItem("cartItems", JSON.stringify(store.getState().cartData));
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: Constant.DELETE_FROM_CART, payload: item });
    localStorage.setItem("cartItems", JSON.stringify(store.getState().cartData));
  };
};
//delete all from cart
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: Constant.DELETE_ALL_FROM_CART });
    localStorage.setItem("cartItems", JSON.stringify(store.getState().cartData));
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter(single => single.color === color)[0]
      .size.filter(single => single.name === size)[0].stock;
  }
};
