import { Constant } from 'redux/constants/orders.constant';

const initState = {
    list: []
}

export const ordersListReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.ORDERS_LIST_FETCHING:
            return { ...state, is_loading: true, error: null, list: [] };
        case Constant.ORDERS_LIST_FETCHED:
            return { ...state, is_loading: false, error: null, list: action.payload };
        case Constant.ORDERS_LIST_FETCHING_FAILED:
            return { ...state, is_loading: false, error: action.payload, list: [] };
        default: 
            return state;
    }
}

export const ordersDetailReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.ORDER_DETAIL_FETCHING:
            return { ...state, is_loading: true, error: null, list: [] };
        case Constant.ORDER_DETAIL_FETCHED:
            return { ...state, is_loading: false, error: null, list: action.payload };
        case Constant.ORDER_DETAIL_FETCHING_FAILED:
            return { ...state, is_loading: false, error: action.payload, list: [] };
        default: 
            return state;
    }
}