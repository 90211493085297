import Constant from "redux/constants/reviews.constant";

const initState = {
    list: {}
}

export const reviewsListReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.REVIEWS_LIST_FETCHING:
            return { ...state, is_loading: true, is_error: false, error: null, code: null, list: {} };
        case Constant.REVIEWS_LIST_FETCHED:
            return { ...state, is_loading: false, is_error: false, error: null, code: action.code, list: action.payload };
        case Constant.REVIEWS_LIST_FETCHING_FAILED:
            return { ...state, is_loading: false, is_error: true, error: action.payload, code: action.code, list: {} };
        default:
            return state;
    }
}