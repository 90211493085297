import axios from 'axios';
import { inArray } from './helper';

const currentHost = window.location.host
const currentHostSplitted = currentHost.split('.');
const currentHostLength = currentHostSplitted.length;
let baseURL = process.env.REACT_APP_API_URL;
let hostLengthCompareVal = 1; // for local

if(inArray(process.env.REACT_APP_ENVIRONMENT, ["stage", "staging"])){
    hostLengthCompareVal = 3;
}else if(inArray(process.env.REACT_APP_ENVIRONMENT, ["prod", "production"])){
    hostLengthCompareVal = 3;
}

if(currentHostLength > hostLengthCompareVal){
    var subDomain = currentHostSplitted[0];
    const baseURLSplitted = new URL(baseURL); 
    baseURL = `${baseURLSplitted.protocol}//${subDomain}.${baseURLSplitted.host}${baseURLSplitted.pathname}`
}

export const API = axios.create({
    baseURL
});