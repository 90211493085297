import { v4 as uuid } from 'uuid';


import Constant from "redux/constants/cart.constant";

const initState = [];

export const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;

  if (action.type === Constant.ADD_TO_CART) {
    // for non variant products
    if (product.variation === undefined) {
      const cartItem = cartItems.filter(item => item.id === product.id)[0];
      if (cartItem === undefined) {
       
        return [
          ...cartItems,
          {
            ...product,
            cartItemId: uuid(),
          }
        ];
      } else {
        const itemExists = cartItems.find(item => item.id === product.id);
 
        if(itemExists){
          const size = cartItems.length;
          for(let i = 0; i < size; i++){
            if(cartItems[i].id === product.id){
              cartItems[i] = { ...itemExists, amount: product.amount == 1 ? itemExists.amount + product.amount : product.amount, additions: product.additions, specialRequests: product.specialRequests, additionsPrice: product.additionsPrice, specialRequestsPrice: product.specialRequestsPrice };
              break;
            }
          }
          return [
              ...cartItems
            ];
        }else{
          return [
            ...cartItems,
            {...product, amount:  product.amount, additions: product.additions, specialRequests: product.specialRequests, additionsPrice: product.additionsPrice, specialRequestsPrice: product.specialRequestsPrice }
          ]
        }
          
      }
      // for variant products
    } else {
      const cartItem = cartItems.filter(
        item =>
          item.id === product.id &&
          product.selectedProductColor &&
          product.selectedProductColor === item.selectedProductColor &&
          product.selectedProductSize &&
          product.selectedProductSize === item.selectedProductSize &&
          (product.cartItemId ? product.cartItemId === item.cartItemId : true)
      )[0];

      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid()
          }
        ];
      } else if (
        cartItem !== undefined &&
        (cartItem.selectedProductColor !== product.selectedProductColor ||
          cartItem.selectedProductSize !== product.selectedProductSize)
      ) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid()
          }
        ];
      } else {
        return cartItems.map(item =>
          item.cartItemId === cartItem.cartItemId
            ? {
                ...item,
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity + 1,
                selectedProductColor: product.selectedProductColor,
                selectedProductSize: product.selectedProductSize
              }
            : item
        );
      }
    }
  }

  if (action.type === Constant.DECREASE_QUANTITY) {
    if (product.amount === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map(item =>
        item.cartItemId === product.cartItemId
          ? { ...item, amount: item.amount - 1 }
          : item
      );
    }
  }

  if (action.type === Constant.DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    return remainingItems(cartItems, product);
  }

  if (action.type === Constant.DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }

  return state;
};
