const Constant = {
    ORDER_NEW: 0,
    ORDER_ACCEPTED: 1,
    ORDER_REJECTED: -1,
    ORDER_COMPLETED: 2,
    ORDER_CANCELLED: -2,
    MEAL_TYPEOF_OFFERS: 5,
    MEAL_TYPEOF_BEVERAGE: 4,
    MEAL_TYPEOF_MEALS: 3,
    MEAL_TYPEOF_DESSERT: 2,
    OFFERS_IMAGE_URL: `${process.env.REACT_APP_BACKEND_URL}/cpanel/upload/Offers/`,
    MEALS_IMAGE_URL: `${process.env.REACT_APP_BACKEND_URL}/cpanel/upload/meal/`,
    PROFILE_IMAGE_URL: `${process.env.REACT_APP_BACKEND_URL}/cpanel/upload/user/`
}

export default Constant;