import { API } from "common/utils/api";
import { Constant } from "../constants/generalSetting.constant";

export const fetchGeneralSetting = () => async(dispatch, getState) => {
    dispatch({ type: Constant.GENERAL_SETTING_FETCHING });

    const branchId = JSON.parse(localStorage.getItem("branch_id"));
    const config = {
      headers:{
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${getState().login.user.token}`  
      }
    }
    const params = {
        kitchen_id: branchId
    }

    const { data } = await API.get('/store/setting', params, config);
    if(data.code == "SUCCESS"){
        dispatch({
            type: Constant.GENERAL_SETTING_FETCHED,
            payload: data.data
        });
    }else{
        dispatch({
            type: Constant.GENERAL_SETTING_FETCHING_FAILED,
            payload: data.message
        });
    }
}

export const clearGeneralSettingErrors = () => async(dispatch) => {
    dispatch({ type: Constant.GENERAL_SETTING_CLEAR_ERRORS });
}