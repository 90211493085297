import { Constant } from "redux/constants/profile.constant";

const initState = {
    user: {}
}

export const profileReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.USER_PROFILE_FETCHING:
            return { ...state, is_loading: true, error: null, user: {} };
        case Constant.USER_PROFILE_FETCHED:
            return { ...state, is_loading: false, error: null, user: action.payload };
        case Constant.USER_PROFILE_FETCHING_FAILED:
            return { ...state, is_loading: false, error: action.payload, user: {} };
        default:
            return state;
    }
}