import { Constant } from 'redux/constants/meals.constant';

const initState = {
    list: []
}

export const mealsReducer = (state = initState, action) => {
    switch (action.type) {
        case Constant.MEALS_FETCHING:
            return {...state, is_loading: true, error: null, list: [] };
        case Constant.MEALS_FETCHED:
            return {...state, is_loading: false, error: null, list: action.payload };
        case Constant.MEALS_FETCHING_FAILED:
            return {...state, is_loading: false, error: action.payload, list: [] };
            case Constant.MEALS_DETAIL_FETCHING:
            return {...state, is_loading: true, error: null, mealDetail: [] };
        case Constant.MEALS_DETAIL_FETCHED:
            return {...state, is_loading: false, error: null, mealDetail: action.payload };
        case Constant.MEALS_DETAIL_FETCHING_FAILED:
            return {...state, is_loading: false, error: action.payload, mealDetail: [] };
        default:
            return state;
    }
}