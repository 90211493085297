import Constant from "redux/constants/branches.constant";

const initState = {
    detail:{}
}

export const branchDetailReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.BRANCH_DETAIL_FETCHING:
            return { ...state, is_loading: true, is_error: false, error: null, code: null, detail: {} };
        case Constant.BRANCH_DETAIL_FETCHED:
            return { ...state, is_loading: false, is_error: false, error: null, code: action.code, detail: action.payload };
        case Constant.BRANCH_DETAIL_FETCHING_FAILED:
            return { ...state, is_loading: false, is_error: true, error: action.payload, code: action.code, detail: {} };
        default:
                return state;
    }
}