import { Constant } from 'redux/constants/offers.constant';

const initState = {
    list: []
}

export const offersReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.OFFERS_FETCHING:
            return { ...state, is_loading: true, error: null, list: [] };
        case Constant.OFFERS_FETCHED:
            return { ...state, is_loading: false, error: null, offer: action.payload };
        case Constant.OFFERS_FETCHING_FAILED:
            return { ...state, is_loading: false, error: action.payload, list: [] };
        default: 
            return state;
    }
}