import { Constant } from "redux/constants/deal.constant";

const initState = {
    deal: []
}

export const DealOfDayReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.DEAL_FETCHING:
            return { ...state, is_loading: true, error: null, deal: [] };
        case Constant.DEAL_FETCHED:
            return { ...state, is_loading: false, error: null, deal: action.payload };    
        case Constant.DEAL_FETCHING:
            return { ...state, is_loading: false, error: action.payload, deal: [] };
        default:
            return state;
    }
}