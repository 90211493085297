import Constant from "redux/constants/branches.constant";
import { API } from "common/utils/api";
import { httpCode } from "common/utils/helper";

export const fetchBranches = ({lat, lng}) => async(dispatch) => {
    try{
        dispatch({ type: Constant.BRANCHES_FETCHING });

        const params = {latitude: lat, longitude: lng};
        const { data } = await API.post('/user/getKitchens', params);
        
        if(data.code === "SUCCESS"){
            dispatch({
                type: Constant.BRANCHES_FETCHED,
                payload: data.data.kitchens,
                code: httpCode()
            });
        }
        // else{
        //     dispatch({
        //         type: Constant.BRANCHES_FETCHING_FAILED,
        //         payload: data.message,
        //         code: httpCode(data.code)
        //     });

        //     toast.error(data.message, {theme: "colored"});
        // }
    }catch(error){
        dispatch({
            type: Constant.BRANCHES_FETCHING_FAILED,
            payload: "Invalid URL entered, Store not found",
            code: 500
        });
    }
}