import Constant from "redux/constants/wishlist.constant";
  
  const initState = [];
  
  export const wishlistReducer = (state = initState, action) => {
    const wishlistItems = state,
      product = action.payload;
  
    if (action.type === Constant.ADD_TO_WISHLIST) {
      const wishlistItem = wishlistItems.filter(
        item => item.id === product.id
      )[0];
      if (wishlistItem === undefined) {
        return [...wishlistItems, product];
      } else {
        return wishlistItems;
      }
    }
  
    if (action.type === Constant.DELETE_FROM_WISHLIST) {
      const remainingItems = (wishlistItems, product) =>
        wishlistItems.filter(wishlistItem => wishlistItem.id !== product.id);
      return remainingItems(wishlistItems, product);
    }
  
    if (action.type === Constant.DELETE_ALL_FROM_WISHLIST) {
      return wishlistItems.filter(item => {
        return false;
      });
    }
  
    return wishlistItems;
  };
  
