
import Constant from "redux/constants/promotional_offers.constant";

const initState = {
    list: []
};

export const promotionalOffersList = (state = initState, action) => {
    switch(action.type){
        case Constant.PROMOTIONAL_OFFERS_FETCHING:
            return { ...state, is_loading: true, is_error: false, error: null, code: action.code, list: [] };
        case Constant.PROMOTIONAL_OFFERS_FETCHED:
            return { ...state, is_loading: false, is_error: false, error: null, code: action.code, list: action.payload };
        case Constant.PROMOTIONAL_OFFERS_FETCHING_FAILED:
            return { ...state, is_loading: false, is_error: true, error: action.payload, code: action.code, list: [] };
        default: 
            return state;
    }
}