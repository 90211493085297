import { Constant } from "redux/constants/login.constant";

const initState = {
    user: {}
};

export const loginReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.USER_LOGIN_IN_PROCESS:
            return { ...state, is_loading: true, error: null, user: {} };
        case Constant.USER_LOGGED_IN_SUCCESS:
            return { ...state, is_loading: false, error: null, user: action.payload };
        case Constant.USER_LOGIN_IN_FAILED:
            return { ...state, is_loading: false, error: action.payload, user: {} };
        case Constant.USER_LOGOUT:
            return { is_loading: false, error: null, user: {} };
        default:
            return state;
    }
}