import Constant from "redux/constants/branches.constant";

const initState = {
    list: []
};

export const branchesReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.BRANCHES_FETCHING:
            return { ...state, is_loading: true, is_error: false, error: null, code: action.code, list: [] };
        case Constant.BRANCHES_FETCHED:
            return { ...state, is_loading: false, is_error: false, error: null, code: action.code, list: action.payload };
        case Constant.BRANCHES_FETCHING_FAILED:
            return { ...state, is_loading: false, is_error: true, error: action.payload, code: action.code, list: [] };
        default:
            return state;
    }
}