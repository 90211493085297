import Constant from '../../constants/layout_setting.constant';

const initState = {
    setting: {}
};

export const layoutSettingReducer = (state = initState, action) => {
    switch(action.type){
        case Constant.LAYOUT_SETTING_FETCHING:
            return { ...state, is_loading: true, is_error: false, error: null, code: action.code, setting: {} };
        case Constant.LAYOUT_SETTING_FETCHED:
            return { ...state, is_loading: false, is_error: false, error: null, code: action.code, setting: action.payload };
        case Constant.LAYOUT_SETTING_FETCHING_FAILED:
            return { ...state, is_loading: false, is_error: true, error: action.payload, code: action.code, setting: {} };
        default:
            return state;
    }
};