
import Constant from "redux/constants/search.constant";

const initState = [];
export  const searchText = (state = initState, action) => {

 if (action.type === Constant.SEARCH_FETCHING) {
    return { ...state, is_loading: false, error: null, searchText: action.payload };
}
return state;
}