import Constant from "redux/constants/layout_setting.constant";
import { API } from "common/utils/api";
import { httpCode } from 'common/utils/helper';

export const fetchLayoutSetting = () => async(dispatch) => {
    try{
        dispatch({ type: Constant.LAYOUT_SETTING_FETCHING });
    
        const params = {
            "isWeb": true
        }

        const { data } = await API.get('/layout/setting/1');
        
        if(data.code === "SUCCESS"){
            dispatch({
                type: Constant.LAYOUT_SETTING_FETCHED,
                payload: data.data,
                code: httpCode(data.code)
            });
        }else{
            dispatch({
                type: Constant.LAYOUT_SETTING_FETCHING_FAILED,
                payload: data.message,
                code: httpCode(data.code)
            });
        }
    }catch(error){
        dispatch({
            type: Constant.LAYOUT_SETTING_FETCHING_FAILED,
            payload: "Invalid URL entered, Store not found",
            code: 500
        });
    }
}