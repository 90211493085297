import Constant from "./constant"

export const orderStatus = (statusCode) => {
    switch(statusCode){
        case Constant.ORDER_ACCEPTED:
            return "Accepted";
        case Constant.ORDER_REJECTED:
            return "Rejected";
        case Constant.ORDER_COMPLETED:
            return "Completed";
        case Constant.ORDER_CANCELLED:
            return "Cancelled";
        default:
            return "New";
    }
}

export const subDomainFound = () => {
    let found = true;
    const currentHost = window.location.host
    const currentHostSplitted = currentHost.split('.');
    const currentHostLength = currentHostSplitted.length;
    let hostLengthCompareVal = 1; // for local

    if(inArray(process.env.REACT_APP_ENVIRONMENT, ["stage", "staging"])){
        hostLengthCompareVal = 3;
    }else if(inArray(process.env.REACT_APP_ENVIRONMENT, ["prod", "production"])){
        hostLengthCompareVal = 3;
    }

    if(currentHostLength === hostLengthCompareVal){
        found = false;
    }

    return found;
}

export const inArray = (needle, haystack) => {
    const size = haystack.length;
    for(let indx=0; indx< size; indx++){
        if(haystack[indx] === needle){
            return true;
        }
    }
    return false;
}

export const httpCode = (str) => {

    switch(str){
        case str === "ERROR":
           return 404;
        case str === "SERVER_ERROR":
            return 500;
        default:
            return 200;
    }
}

export const mealTypeName = (id) => {
    switch(parseInt(id)){
        case 2:
            return "Dessert";
        case 3:
            return "Meals";
        case 4:
            return "Beverage";
        case 5:
            return "Offers";
        default:
            return "Home";
    }
}