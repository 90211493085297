import { Constant } from "redux/constants/signup.constant";

const initState = {
  data: {},
};

export const signupReducer = (state = initState, action) => {
  switch (action.type) {
    case Constant.SIGNUP_REQUEST:
      return { ...state, is_loading: true, error: null, data: {} };
    case Constant.SIGNUP_SUCCESS:
      return {
        ...state,
        is_loading: false,
        error: null,
        data: action.payload,
      };
    case Constant.SIGNUP_FAILED:
      return { ...state, is_loading: false, error: action.payload, data: {} };
    default:
      return state;
  }
};
